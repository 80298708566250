import React from 'react';

import TraitList from './TraitList';

export default function Occupation(props) {
    const occupations = ["Software Engineer", "Doctor", "Director", "Manager", "Lawyer"];

    return (
        <TraitList
            category={"Occupation"}
            list={occupations}
            selected={props.orderTraits["Occupation"]}
            onAdd={props.onAdd}
            onRemove={props.onRemove} />
    );
}