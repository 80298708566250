import React from 'react';
import './PhoneThreadViewer.css';

export default function PhoneThreadViewer(props) {
    return (
        <div className="phone-border">
            <div className="phone-base">
                <div className="phone-navbar-message-groups">
                    <div className="phone-navbar">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M15 6l-6 6l6 6" />
                        </svg>
                        <p className="phone-name-text">Adriana Maunu</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M15 6l-6 6l6 6" />
                        </svg>
                    </div>
                    <div className="phone-message-groups">
                        <div className="phone-message-group">
                            <div className="phone-request-message">
                                <p className="phone-request-message-text">Hello! Do you meal plan? Why or why not?</p>
                            </div>
                            <div className="phone-response-messages">
                                <div className="phone-response-bar" />
                                <div className="phone-response-message">
                                    <p className="phone-response-message-text">Yes, I usually plan all meals for the week before I go grocery shopping. It saves us so much time and it avoids the daily “What are we eating tonight?” conversation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="phone-message-group">
                            <div className="phone-request-message">
                                <p className="phone-request-message-text">Do you use any software to do your meal planning?</p>
                            </div>
                            <div className="phone-response-messages">
                                <div className="phone-response-bar" />
                                <div className="phone-response-message">
                                    <p className="phone-response-message-text">Currently, I have a note on my phone with a table for all the meals we're going to have each week. I share this with my family. I also have a grocery list note that I fill out once the meal planning note is done.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="phone-text-box">
                    <p className="phone-text-box-text">Type your message</p>
                </div>
            </div>
        </div>
    );
}