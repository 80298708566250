import React from 'react';

export default function TheRisksThatEveryStartupFaces(props) {
    return (
        <div className="blog-post-container">
            <div className="blog-post-header">
                <h1 className="blog-post-title-text">The Risks that Every Startup Faces</h1>
                <div className="blog-post-author-date">
                    <div className="blog-post-author">
                        <h3 className="blog-post-author-name">Prithvi Ranjan</h3>
                        <h4 className="blog-post-author-title">Co-founder and CEO</h4>
                    </div>
                    <div className="blog-post-date">
                        <p className="blog-post-date-text">June 10, 2024</p>
                    </div>
                </div>
            </div>
            <div className="blog-post-content-section">
                <p className="blog-post-text">When you think of a startup idea, it's an intoxicating feeling. You put on the rose-tinted glasses and might ignore the risks that every startup eventually encounters.</p>
                <p className="blog-post-text">Any of these risks can destroy your startup, but if you're aware of them early enough, then it's possible to mitigate them.</p>
                <p className="blog-post-text">In future posts, we'll go through strategies for how to mitigate these risks, or identifying when a risk is too high for a startup. For now, answer these questions for your startup!</p>
            </div>
            <div className="blog-post-content-section">
                <ol>
                    <li class="blog-post-text"><strong>Demand risk</strong>
                        <ol>
                            <li class="blog-post-text">Does anyone want it?</li>
                            <li class="blog-post-text">Does anyone want it badly?</li>
                            <li class="blog-post-text">Do you know exactly who wants it badly?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Opportunity size risk</strong>
                        <ol>
                            <li class="blog-post-text">Do enough people want it badly?</li>
                            <li class="blog-post-text">Do enough people who can pay for it want it badly?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Technology risk</strong>
                        <ol>
                            <li class="blog-post-text">Can it be built?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Team risk</strong>
                        <ol>
                            <li class="blog-post-text">Can it be built by this team?</li>
                            <li class="blog-post-text">Can it be distributed by this team?</li>
                            <li class="blog-post-text">Can this team work together?</li>
                            <li class="blog-post-text">Can this team work together through hard times?</li>
                            <li class="blog-post-text">Can this team work together for a long time?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Product risk</strong>
                        <ol>
                            <li class="blog-post-text">Can you build it?</li>
                            <li class="blog-post-text">Can you quickly get feedback on it?</li>
                            <li class="blog-post-text">Can you iterate quickly?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Legal risk</strong>
                        <ol>
                            <li class="blog-post-text">Is it compliant with the law?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Distribution risk</strong>
                        <ol>
                            <li class="blog-post-text">Can you reach the people who want it badly?</li>
                            <li class="blog-post-text">Can you reach enough people who want it badly?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Funding risk</strong>
                        <ol>
                            <li class="blog-post-text">Do you have funds for it?</li>
                            <li class="blog-post-text">Can you get the funds for it?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Integration risk</strong>
                        <ol>
                            <li class="blog-post-text">Does it easily fit in a customer's life?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Competition risk</strong>
                        <ol>
                            <li class="blog-post-text">Do you have a cost advantage?</li>
                            <li class="blog-post-text">Do you have a network effect advantage?</li>
                            <li class="blog-post-text">Do you have a switching cost advantage?</li>
                            <li class="blog-post-text">Do you have an intangible asset advantage?</li>
                        </ol>
                    </li>
                    <li class="blog-post-text"><strong>Scaling risk</strong>
                        <ol>
                            <li class="blog-post-text">Can it be built at scale, profitably?</li>
                            <li class="blog-post-text">Can you reach enough people who want it badly, profitably?</li>
                            <li class="blog-post-text">Can your team scale?</li>
                            <li class="blog-post-text">Do you have the funds to scale?</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    );
}