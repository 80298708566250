import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserverseLogo from '../../images/userverse-small-logo.svg';

export default function NavbarLogo(props) {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className="navbar-logo-container" onClick={handleLogoClick}>
            <img src={UserverseLogo} className="navbar-logo" alt="Userverse logo" />
        </div>
    );
}