import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useAuth } from '../../hooks';
import OrderCreation from './OrderCreation';
import OrderCheckout from './OrderCheckout/OrderCheckout';

export default function OrderingController(props) {
    const auth = useAuth();

    const [state, setState] = useState(props.orderingState);
    const [order, setOrder] = useState(null);
    const [selectedSection, setSelectedSection] = useState(0);

    useEffect(() => {
        setState(props.orderingState);
    }, [props.orderingState]);

    const exit = (e) => {
        if (e === undefined || e.target === e.currentTarget) {
            props.deactivate();
            setOrder(null);
        }
    };

    const nextState = (payload) => {
        if (state === "ORDERING") {
            setOrder(payload);
            if (auth.user) {
                setState("ORDER_CHECKOUT");
            } else {
                props.activateAuth();
            }
        } else if (state === "ORDER_CHECKOUT") {
            placeOrder(payload);
        }
    };

    const editOrder = (section) => {
        setSelectedSection(section);
        setState("ORDERING");
    };

    const placeOrder = (fare) => {
        var payload = order;
        payload["requester_uuid"] = auth.user['user_uuid'];
        payload["base_fare"] = fare;
        console.log(payload);
        axios.post('/requester/threads', JSON.stringify(payload), {
            headers: {
                'Authorization': auth.user['auth_token']
            }
        }).then(() => {
            setOrder(null);
            setSelectedSection(0);
            props.deactivate();
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            {
                state === "ORDERING" && <OrderCreation order={order} selectedSection={selectedSection} next={nextState} exit={exit} />
            }
            {
                state === "ORDER_CHECKOUT" && <OrderCheckout order={order} editOrder={(section) => editOrder(section)} next={nextState} exit={exit} />
            }
        </>
    );
}