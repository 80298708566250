import React from 'react';

import { useAuth } from '../hooks';
import ResponderAuthenticatedPage from './ResponderAuthenticatedPage';
import ResponderUnauthenticated from './ResponderUnauthenticated';

export default function ResponderLandingPage(props) {
    const auth = useAuth();
    
    return (
        auth.user ? (
            <ResponderAuthenticatedPage />
        ) : (
            <ResponderUnauthenticated />
        )
    );
}