import React from 'react';
import './ResponderUnauthenticated.css';

import { AppStoreLink, Navbar, Footer } from '../../components';

export default function ResponderUnauthenticated(props) {
    return (
        <div className="page-base gray">
            <Navbar type="RESPONDER" />
            <section className="content-background white">
                <div className="content-section">
                    <div className="responder-marketing-header">
                        <div className="user-marketing-header-left">
                            <div className="user-marketing-header-text black-header-1-text">Earn money by answering questions.</div>
                            <p className="black-regular-4-text">Thousands of startups need your feedback and insights in order to be successful. Provide insights to the next great companies while earning an income.</p>
                            <AppStoreLink link="https://apps.apple.com/id/app/userverse/id6499307197" />
                            {/* <div className="user-marketing-register">
                                <input className="text-input-box-no-border-gray-placeholder" type="text" placeholder="Your email" onKeyDown={(e) => handleEnter(e)} {...bindEmail} />
                                <div className="large-button-submit" onClick={activateAuthModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M5 12l14 0"></path>
                                        <path d="M13 18l6 -6"></path>
                                        <path d="M13 6l6 6"></path>
                                    </svg>
                                </div>
                            </div> */}
                        </div>
                        <div className="user-marketing-header-right">
                            <div className="user-marketing-header-image"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}