import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RequesterAuthenticatedNavbar.css';

import NavbarLogo from '../NavbarLogo';
import OrderButton from '../../ordering/OrderButton';

import { useAuth } from '../../../hooks';

export default function RequesterAuthenticatedNavbar(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const goToEarn = () => {
        navigate('/earn');
    };

    const signOut = () => {
        auth.handleSignOut();
    };

    return (
        <header className="requester-authenticated-navbar border">
            <div className="requester-authenticated-navbar-container">
                <NavbarLogo />
                    {
                        props.orderingState !== "ORDERING" && props.orderingState !== "ORDER_CHECKOUT" && (<OrderButton onClick={props.activateOrdering} />)
                    }
                <div className="navbar-actions-container">
                    {/* <div className="earn-as-user-button" onClick={goToEarn}>
                        <p className="black-header-5-text">Earn as a User</p>
                    </div> */}
                    <div className="user-profile-dropdown">
                        <div className="user-button" onClick={(e) => props.toggleUserProfileDropdownState(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                            </svg>
                        </div>
                        {
                            props.userProfileDropdownState && (
                                <div className="user-profile-dropdown-content">
                                    {/* <div className="user-profile-dropdown-item" onClick={goToEarn}>
                                        <p className="black-regular-5-text">Earn as a User</p>
                                    </div>
                                    <hr className="user-profile-dropdown-divider" /> */}
                                    <div className="user-profile-dropdown-item" onClick={signOut}>
                                        <p className="black-header-5-text">Log out</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </header>  
    );
}