import React from 'react';
import './Jobs.css';

import { Navbar, Footer } from '../../components';

export default function Jobs(props) {
    return (
        <div className="page-base">
            <Navbar type="REQUESTER" />
            <section className="content-background white">
                <div className="content-section">
                    <div className="jobs-section">
                        <div className="jobs-section-header">
                            <h1 className="jobs-section-header-text">Jobs</h1>
                        </div>

                        <section className="jobs-section-detail-container">
                            <p className="jobs-section-detail-text">Send your resume to userverse.team@gmail.com.</p>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}