import React from 'react';
import { useNavigate } from 'react-router';
import './UseCaseList.css';

export default function UseCaseList(props) {
    const navigate = useNavigate();

    const handleClick = (to) => {
        navigate(to);
    };

    return (
        <div className="FullWidthCarousel">
            <div className="FullWidthCarousel__track">
                <div className="FullWidthCarousel__spacer" />
                <div className="UseCaseCard" onClick={() => handleClick("/use-cases/user-research")}>
                    <div className="UseCaseCard__background color1" />
                    <div className="UseCaseCard__background UseCaseCard__background--hover" />
                    <div className="UseCaseCard__content">
                        <header className="UseCaseCard__header">
                            <h1 className="UseCaseCard__title">User research</h1>
                        </header>
                        <div className="UseCaseCard__body">
                            <p>Understand your customers, their pain points, and how they make decisions.</p>
                        </div>
                        {/* <div className="UseCaseCard__footer">
                            <div className="use-case-item-action">
                                <p className="use-case-item-action-text">Explore</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="UseCaseCard" onClick={() => handleClick("/use-cases/validation")}>
                    <div className="UseCaseCard__background color2" />
                    <div className="UseCaseCard__background UseCaseCard__background--hover" />
                    <div className="UseCaseCard__content">
                        <header className="UseCaseCard__header">
                            <h1 className="UseCaseCard__title">Validation</h1>
                        </header>
                        <div className="UseCaseCard__body">
                            <p>Gain confidence that the problem you're solving is real and significant.</p>
                        </div>
                        {/* <div className="UseCaseCard__footer">
                            <div className="use-case-item-action">
                                <p className="use-case-item-action-text">Explore</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="UseCaseCard" onClick={() => handleClick("/use-cases/feedback")}>
                    <div className="UseCaseCard__background color3" />
                    <div className="UseCaseCard__background UseCaseCard__background--hover" />
                    <div className="UseCaseCard__content">
                        <header className="UseCaseCard__header">
                            <h1 className="UseCaseCard__title">Feedback</h1>
                        </header>
                        <div className="UseCaseCard__body">
                            <p>Iterate on your product faster by getting continuous insights and feedback.</p>
                        </div>
                        {/* <div className="UseCaseCard__footer">
                            <div className="use-case-item-action">
                                <p className="use-case-item-action-text">Explore</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="UseCaseCard" onClick={() => handleClick("/use-cases/beta-testing")}>
                    <div className="UseCaseCard__background color4" />
                    <div className="UseCaseCard__background UseCaseCard__background--hover" />
                    <div className="UseCaseCard__content">
                        <header className="UseCaseCard__header">
                            <h1 className="UseCaseCard__title">Beta testing</h1>
                        </header>
                        <div className="UseCaseCard__body">
                            <p>Prepare your product for primetime by catching bugs and issues before your customers do.</p>
                        </div>
                        {/* <div className="UseCaseCard__footer">
                            <div className="use-case-item-action">
                                <p className="use-case-item-action-text">Explore</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="UseCaseCard" onClick={() => handleClick("/use-cases/opportunity-identification")}>
                    <div className="UseCaseCard__background color5" />
                    <div className="UseCaseCard__background UseCaseCard__background--hover" />
                    <div className="UseCaseCard__content">
                        <header className="UseCaseCard__header">
                            <h1 className="UseCaseCard__title">Opportunity discovery</h1>
                        </header>
                        <div className="UseCaseCard__body">
                            <p>Identify specific pain points in any market by speaking to people in the domain.</p>
                        </div>
                        {/* <div className="UseCaseCard__footer">
                            <div className="use-case-item-action">
                                <p className="use-case-item-action-text">Explore</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M9 6l6 6l-6 6" />
                                </svg>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="FullWidthCarousel__spacer" />
            </div>
        </div>
    );
}