import React, { useEffect, useState } from 'react';

import { useInput } from '../../hooks';

export default function TraitList(props) {
    const [filteredList, setFilteredList] = useState([]);
    const { value: search, bind: bindSearch } = useInput("");

    useEffect(() => {
        var searchTerm = search;
        var tokens = searchTerm.toLowerCase().split(' ').filter((token) => {
            return token.trim() !== '';
        });
        if (tokens.length) {
            var searchTermRegex = new RegExp(tokens.join('|'), 'gim');
            var filteredList = props.list.filter((item) => {
                let itemString = item.toLowerCase().trim() + ' ';
                return itemString.match(searchTermRegex);
            });
            setFilteredList(filteredList);
        } else {
            setFilteredList(props.list);
        }
    }, [search]);

    return (
        <div className="opportunity-characteristic-section">
            <div className="opportunity-characteristic-search-bar">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="15" height="15" viewBox="0 0 24 24" strokeWidth="2" stroke="#DADADA" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>
                <input className="text-input-box-no-border-gray-placeholder" type="text" placeholder="Search" {...bindSearch} />
            </div>
            <div className="opportunity-characteristic-options">
                {
                    filteredList.map((item) => 
                        <div key={item} className={`opportunity-characteristic-option ${props.selected.includes(item) ? 'active' : ''}`} onClick={props.selected.includes(item) ? () => props.onRemove(props.category, item) : () => props.onAdd(props.category, item)}>
                            {
                                props.selected.includes(item) ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="15" height="15" viewBox="0 0 24 24" strokeWidth="3" stroke="#609C5E" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                        <path d="M9 12l2 2l4 -4"></path>
                                    </svg>
                                ) : (
                                    <div className="occupation-characteristic-unselected-space" />
                                )
                            }
                            <p className="black-regular-5-text">{item}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}