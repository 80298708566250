import React, { useState } from 'react';
import './OrderCreation.css';

import { Occupation, Industry } from '../../persona';

import { useInput } from '../../../hooks';

export default function OrderCreation(props) {
    const [selectedSection, setSelectedSection] = useState(props.selectedSection);

    const [selectedTraitCategory, setSelectedTraitCategory] = useState("Occupation");
    const [orderTraits, setOrderTraits] = useState(props.order !== null ? props.order['requested_traits'] : {
        "Occupation": [],
        "Industry": []
    });

    const traitCategories = ["Occupation", "Industry", "Age", "Gender", "Education", "Income", "Tech Literacy", "Interests"];

    const [quantity, setQuantity] = useState(props.order !== null ? props.order['requested_quantity'] : 10);
    const { value: message, bind: bindMessage } = useInput(props.order !== null ? props.order['request_payload'] : "");

    const handleQuantityClick = () => {
        setSelectedSection(0);
    };

    const increment = () => {
        if (quantity < 50) {
            setQuantity(value => value + 1);
        }
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(value => value - 1);
        }
    };

    const handleMessageClick = () => {
        setSelectedSection(2);
        const textInput = document.getElementById("message-input-box");
        textInput.focus();
    };
    
    const handleTraitCategorySelection = (e, selection) => {
        e.stopPropagation();
        setSelectedTraitCategory(selection);
    };

    const addTrait = (category, trait) => {
        let newOrderTraits = {...orderTraits};
        newOrderTraits[category].push(trait);
        setOrderTraits(newOrderTraits);
    };

    const removeTrait = (category, trait) => {
        let newOrderTraits = {...orderTraits};
        const index = newOrderTraits[category].indexOf(trait);
        if (index > -1) {
            newOrderTraits[category].splice(index, 1);
        }
        setOrderTraits(newOrderTraits);
    };

    const formatQuantity = () => {
        if (quantity === 1) {
            return "1 user";
        }
        return quantity + " users";
    };
    
    const formatPersona = () => {
        var personaString = "";
        if (orderTraits["Occupation"].length > 0) {
            personaString += orderTraits["Occupation"][0] + ", ";
        }
        if (orderTraits["Industry"].length > 0) {
            personaString += orderTraits["Industry"][0] + ", ";
        }
        if (personaString === "") {
            return "Anyone";
        } else {
            return personaString.substring(0, personaString.length - 2);
        }
    };
    
    const handleOrder = (e) => {
        e.stopPropagation();
        if (message === "") {
            setSelectedSection(2);
        } else {
            props.next({
                "requested_traits": orderTraits,
                "requested_quantity": quantity,
                "request_payload": message
            });
        }
    };

    return (
        <div className="gray-modal-background" onClick={(e) => props.exit(e)}>
            <div className="ordering-base">
                <div className="ordering-pill">
                    <div className="ordering-pill-sections">
                        {
                            <div className={`ordering-pill-section quantity ${selectedSection === 0 ? 'active' : ''}`} onClick={handleQuantityClick}>
                                <h5 className="black-header-6-text">Quantity</h5>
                                <div className="ordering-pill-quantity">
                                    <div className="ordering-pill-quantity-text-container">
                                        <p className="black-regular-5-text">{formatQuantity()}</p>
                                    </div>
                                    <div className={`ordering-pill-quantity-dropdown ${selectedSection === 0 ? 'visible' : ''}`}>
                                        <div className="ordering-pill-quantity-dropdown-content">
                                            <div className="ordering-pill-quantity-dropdown-change-quantity" onClick={decrement}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-minus" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                                    <path d="M9 12l6 0" />
                                                </svg>
                                            </div>
                                            <div className="ordering-pull-quantity-dropdown-text">
                                                <p className="black-regular-5-text">{quantity}</p>
                                            </div>
                                            <div className="ordering-pill-quantity-dropdown-change-quantity" onClick={increment}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-plus" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                    <path d="M9 12h6" />
                                                    <path d="M12 9v6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            <div className={`ordering-pill-section persona ${selectedSection === 1 ? 'active' : ''}`} onClick={() => setSelectedSection(1)}>
                                <h5 className="black-header-6-text">Persona</h5>
                                <p className="black-regular-5-text">{formatPersona(orderTraits)}</p>
                                <div className={`ordering-pill-persona-dropdown ${selectedSection === 1 ? 'visible' : ''}`}>
                                    <div className="ordering-pill-persona-characteristics-container">
                                        <div className="ordering-pill-persona-characteristics">
                                            {
                                                traitCategories.map((category) =>
                                                    <div key={category} className={`ordering-pill-persona-characteristic ${selectedTraitCategory === category ? 'active' : ''}`} onClick={(e) => handleTraitCategorySelection(e, category)}>
                                                        <p className="black-header-5-text">{category}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="ordering-pill-persona-characteristic-details">
                                            <p className="black-header-4-text">{selectedTraitCategory}</p>
                                            {
                                                selectedTraitCategory === "Occupation" && <Occupation orderTraits={orderTraits} onAdd={addTrait} onRemove={removeTrait} />
                                            }
                                            {
                                                selectedTraitCategory === "Industry" && <Industry orderTraits={orderTraits} onAdd={addTrait} onRemove={removeTrait} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            <div className={`ordering-pill-section message ${selectedSection === 2 ? 'active' : ''}`} onClick={handleMessageClick}>
                                <h5 className="black-header-6-text">Message</h5>
                                <div className="ordering-pill-message">
                                    <p id={message} className="ordering-pill-message-text">{message}</p>
                                    <div className={`ordering-pill-message-dropdown ${selectedSection === 2 ? 'visible' : ''}`}>
                                        <div className="ordering-pill-message-text-container">
                                            <textarea type="text" id="message-input-box" className="ordering-pill-message-text-input-field" placeholder="Start typing your message..." {...bindMessage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="ordering-pill-submit" onClick={(e) => handleOrder(e)}>
                        <div className="button-submit">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}