export function formatType(type) {
    switch (type) {
        case "USER_RESEARCH":
            return "User Research";
        case "PRODUCT_FEEDBACK":
            return "Product Feedback";
        case "BETA_TESTING":
            return "Beta Testing";
        case "MESSAGING":
            return "Messaging";
        case "LAUNCH_USERS":
            return "Launch Users";
        default:
            return "";
    }
};