import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { Amplify } from 'aws-amplify';


import { ProvideAuth } from './hooks/use-auth.js';
import { ScrollToTop } from './components';
import { CurrentAuthenticatedUser } from './components/auth';
import { About, Blog, BlogPostBase, Jobs, PrivacyPolicy, Support, TermsOfService, BetaTesting, Feedback, OpportunityIdentification, UserResearch, Validation, RequesterLandingPage, ResponderLandingPage } from './pages';

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: '4hu7cirr2l438qqlgid049arcc',
			userPoolId: 'us-west-2_wUQ6Yj0aW',
		}
  	}
});
axios.defaults.baseURL = 'https://p80kujuni9.execute-api.us-west-2.amazonaws.com/Prod';

export default function App(props) {
	return (
		<ProvideAuth>
			<BrowserRouter>
				<CurrentAuthenticatedUser />
				<ScrollToTop>
					<Routes>
						<Route path='/' element={<RequesterLandingPage />} />
						<Route path='/earn' element={<ResponderLandingPage />} />
						<Route path='/privacy-policy' element={<PrivacyPolicy />} />
						<Route path='/terms-of-service' element={<TermsOfService />} />
						<Route path='/use-cases/user-research' element={<UserResearch />} />
						<Route path='/use-cases/validation' element={<Validation />} />
						<Route path='/use-cases/feedback' element={<Feedback />} />
						<Route path='/use-cases/beta-testing' element={<BetaTesting />} />
						<Route path='/use-cases/opportunity-identification' element={<OpportunityIdentification />} />
						<Route path='/blog' element={<Blog />} />
						<Route path='/blog/:slug' element={<BlogPostBase />} />
						<Route path='/support' element={<Support />} />
						<Route path='/about' element={<About />} />
						<Route path='/jobs' element={<Jobs />} />
					</Routes>
				</ScrollToTop>
			</BrowserRouter>
		</ProvideAuth>
	);
}
