export function formatTimestamp(timestamp) {
    const currentDate = new Date();
    const inputDate = new Date(Number(timestamp)); // Convert Unix timestamp to milliseconds
  
    const isSameDay = currentDate.toDateString() === inputDate.toDateString();
    const isYesterday = new Date(currentDate - 86400000).toDateString() === inputDate.toDateString(); // Subtract 24 hours in milliseconds
    const daysAgo = Math.floor((currentDate - inputDate) / 86400000); // Calculate days ago (86400000 ms in a day)
    
    if (isSameDay) {
        return inputDate.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    } else if (isYesterday) {
        return 'Yesterday';
    } else if (daysAgo < 7) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[inputDate.getDay()];
    } else {
        const year = inputDate.getFullYear().toString().slice(-2);
        const month = (inputDate.getMonth() + 1).toString();
        const day = inputDate.getDate().toString();
        
        return `${month}/${day}/${year}`;
    }
};