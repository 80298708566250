import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPostPreview.css';

export default function BlogPostPreview(props) {
    return (
        <div className="blog-post-preview-container">
            <div className="blog-post-preview-category-date">
                <div className="blog-post-preview-metadata-item">
                    <div className="blog-post-preview-vertical-bar" />
                    <h2 className="blog-post-preview-category-text">{props.category}</h2>
                </div>
                <div className="blog-post-preview-metadata-item">
                    <div className="blog-post-preview-vertical-bar" />
                    <h2 className="blog-post-preview-category-text">{props.date}</h2>
                </div>
            </div>
            <div className="blog-post-preview-title-author">
                <Link to={props.link} className="blog-post-preview-title">
                    <h1 className="blog-post-preview-title-text">{props.title}</h1>
                </Link>
                <div className="blog-post-preview-author">
                    <div className="blog-post-preview-author-image-container"></div>
                    <div className="blog-post-preview-author-metadata">
                        <h3 className="blog-post-preview-author-name">{props.authorName}</h3>
                        <h4 className="blog-post-preview-author-title">{props.authorTitle}</h4>
                    </div>
                </div>
            </div>
            <div className="blog-post-preview-description-image">
                <div className="blog-post-preview-description">
                    <p className="blog-post-preview-description-text">{props.preview}</p>
                    <Link to={props.link} className="blog-post-preview-read-more-button">
                        <p className="blog-post-preview-read-more-text">Read more</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="15" height="15" viewBox="0 0 24 24" strokeWidth="3" stroke="#609C5E" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M9 6l6 6l-6 6" />
                        </svg>
                    </Link>
                </div>
                <div className="blog-post-preview-image-container">
                    <img src={props.image} className="blog-post-preview-image" />
                </div>
            </div>
        </div>
    );
}