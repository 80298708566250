import React, { useRef, useState } from 'react'

export default function Modal(props) {
    const buttonRef = useRef(null);
    const [buttonGradientStyle, setButtonGradientStyle] = useState({
        background: 'linear-gradient(90deg, rgba(110,190,106,1), rgba(96,156,94,1))'
    });

    const handleMouseMove = (event) => {
        const { left, top, width, height } = buttonRef.current.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;

        const xPercent = (x / width) * 100;
        const yPercent = (y / height) * 100;

        setButtonGradientStyle({
            background: `radial-gradient(circle at ${xPercent}% ${yPercent}%, rgba(110,190,106,1), rgba(96,156,94,1))`
        });
    };

    return (
        <div className="gray-modal-background-centered" onClick={(e) => props.exit(e)}>
            <div className="modal-container" onClick={() => ""}>
                <div className="modal-header-container">
                    <div className="modal-close" onClick={() => props.exit()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round" preserveAspectRatio="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                    <div className="modal-header-title">
                        <h2 className="black-header-5-text">{props.title}</h2>
                    </div>
                </div>
                <div className="modal-content-container">
                    {
                        props.detail
                    }
                    {
                        props.loading ? (
                            <div className="full-width-button disabled">
                                <div className="loading-circle" />
                            </div>
                        ) : (
                            <div className="full-width-button" key={buttonGradientStyle} ref={buttonRef} onMouseMove={handleMouseMove} style={buttonGradientStyle} onClick={props.handleAction}>{props.action}</div>
                        )
                    }
                    {
                        props.secondaryAction
                    }
                </div>
            </div>
        </div>
    );
}