import React from 'react';
import './DesktopPersonaSelectionViewer.css';

import UserverseLogo from '../../../images/userverse-small-logo.svg';

export default function DesktopPersonaSelectionViewer(props) {
    return (
        <div className="desktop-persona-selection-viewer">
            <div className="desktop-persona-selection-viewer-navbar">
                <div className="desktop-persona-selection-viewer-navbar-logo">
                    <img src={UserverseLogo} className="desktop-persona-selection-viewer-navbar-logo-image" />
                </div>
                <div className="desktop-persona-selection-viewer-persona-button">
                    <div className="desktop-persona-selection-viewer-persona-text-section">
                        <h4 className="desktop-persona-selection-viewer-persona-text">Anyone</h4>
                        <div className="desktop-persona-selection-viewer-persona-text-section-divider" />
                        <h4 className="desktop-persona-selection-viewer-persona-text">10 customers</h4>
                    </div>
                    <div className="desktop-persona-selection-viewer-persona-submit-button">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5 12l14 0" />
                            <path d="M13 18l6 -6" />
                            <path d="M13 6l6 6" />
                        </svg>
                    </div>
                </div>
                <div className="desktop-persona-selection-viewer-navbar-buttons-section">
                    <div className="desktop-persona-selection-viewer-navbar-buttons">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-circle" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                            <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                            <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bell-filled" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#FFFFFF" fill="#FFFFFF" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M14.235 19c.865 0 1.322 1.024 .745 1.668a3.992 3.992 0 0 1 -2.98 1.332a3.992 3.992 0 0 1 -2.98 -1.332c-.552 -.616 -.158 -1.579 .634 -1.661l.11 -.006h4.471z" strokeWidth="0" fill="#FFFFFF" />
                            <path d="M12 2c1.358 0 2.506 .903 2.875 2.141l.046 .171l.008 .043a8.013 8.013 0 0 1 4.024 6.069l.028 .287l.019 .289v2.931l.021 .136a3 3 0 0 0 1.143 1.847l.167 .117l.162 .099c.86 .487 .56 1.766 -.377 1.864l-.116 .006h-16c-1.028 0 -1.387 -1.364 -.493 -1.87a3 3 0 0 0 1.472 -2.063l.021 -.143l.001 -2.97a8 8 0 0 1 3.821 -6.454l.248 -.146l.01 -.043a3.003 3.003 0 0 1 2.562 -2.29l.182 -.017l.176 -.004z" strokeWidth="0" fill="#FFFFFF" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="desktop-persona-selection-viewer-main-content">
                <div className="desktop-persona-selection-viewer-persona-filter-section">
                    <div className="desktop-persona-selection-viewer-persona-filters">
                        <div className="desktop-persona-selection-viewer-persona-filter">
                            <h5 className="desktop-persona-selection-viewer-persona-filter-text">Gender</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M6 9l6 6l6 -6" />
                            </svg>
                        </div>
                        <div className="desktop-persona-selection-viewer-persona-filter">
                            <h5 className="desktop-persona-selection-viewer-persona-filter-text">Industry</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M6 9l6 6l6 -6" />
                            </svg>
                        </div>
                        <div className="desktop-persona-selection-viewer-persona-filter">
                            <h5 className="desktop-persona-selection-viewer-persona-filter-text">Occupation</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M6 9l6 6l6 -6" />
                            </svg>
                        </div>
                        <div className="desktop-persona-selection-viewer-persona-filter">
                            <h5 className="desktop-persona-selection-viewer-persona-filter-text">Education</h5>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M6 9l6 6l6 -6" />
                            </svg>
                        </div>
                    </div>
                    <div className="desktop-persona-selection-viewer-persona-filter-arrows">
                        <div className="desktop-persona-selection-viewer-persona-filter-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M15 6l-6 6l6 6" />
                            </svg>
                        </div>
                        <div className="desktop-persona-selection-viewer-persona-filter-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M9 6l6 6l-6 6" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="desktop-persona-selection-viewer-thread-view">
                    <div className="desktop-persona-selection-viewer-thread-group-list">
                        <div className="desktop-persona-selection-viewer-thread-group">
                            <div className="desktop-persona-selection-viewer-thread-group-traits-list">
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">Female</h6>
                                </div>
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">Software Engineer</h6>
                                </div>
                            </div>
                            <div className="desktop-persona-selection-viewer-thread-list">
                                <div className="desktop-persona-selection-viewer-thread">
                                    <div className="desktop-persona-selection-viewer-thread-unread-status">

                                    </div>
                                    <div className="desktop-persona-selection-viewer-thread-data">
                                        <div className="desktop-persona-selection-viewer-thread-responder-time">
                                            <h5 className="desktop-persona-selection-viewer-thread-responder-text">Adriana Maunu</h5>
                                            <p className="desktop-persona-selection-viewer-thread-time">11:31am</p>
                                        </div>
                                        <p className="desktop-persona-selection-viewer-thread-preview-text">I find the design a little confus...</p>
                                    </div>
                                </div>
                                <div className="desktop-persona-selection-viewer-thread">
                                    <div className="desktop-persona-selection-viewer-thread-unread-status">

                                    </div>
                                    <div className="desktop-persona-selection-viewer-thread-data">
                                        <div className="desktop-persona-selection-viewer-thread-responder-time">
                                            <h5 className="desktop-persona-selection-viewer-thread-responder-text">Jenny Oliver</h5>
                                            <p className="desktop-persona-selection-viewer-thread-time">4:28pm</p>
                                        </div>
                                        <p className="desktop-persona-selection-viewer-thread-preview-text">Could you integrate this with m...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="desktop-persona-selection-viewer-thread-group">
                            <div className="desktop-persona-selection-viewer-thread-group-traits-list">
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">College Educated</h6>
                                </div>
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">Marketing</h6>
                                </div>
                            </div>
                            <div className="desktop-persona-selection-viewer-thread-list">
                                <div className="desktop-persona-selection-viewer-thread">
                                    <div className="desktop-persona-selection-viewer-thread-unread-status">

                                    </div>
                                    <div className="desktop-persona-selection-viewer-thread-data">
                                        <div className="desktop-persona-selection-viewer-thread-responder-time">
                                            <h5 className="desktop-persona-selection-viewer-thread-responder-text">Carlos Alvarez</h5>
                                            <p className="desktop-persona-selection-viewer-thread-time">1:12pm</p>
                                        </div>
                                        <p className="desktop-persona-selection-viewer-thread-preview-text">The second line is very direct a...</p>
                                    </div>
                                </div>
                                <div className="desktop-persona-selection-viewer-thread">
                                    <div className="desktop-persona-selection-viewer-thread-unread-status">

                                    </div>
                                    <div className="desktop-persona-selection-viewer-thread-data">
                                        <div className="desktop-persona-selection-viewer-thread-responder-time">
                                            <h5 className="desktop-persona-selection-viewer-thread-responder-text">Shelley Brown</h5>
                                            <p className="desktop-persona-selection-viewer-thread-time">6:01pm</p>
                                        </div>
                                        <p className="desktop-persona-selection-viewer-thread-preview-text">What do you mean by "mainta...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="desktop-persona-selection-viewer-thread-detail-view">
                        <div className="desktop-persona-selection-viewer-thread-detail-view-responder-metadata">
                            <h3 className="desktop-persona-selection-viewer-thread-detail-view-responder-text">Jenny Oliver</h3>
                            <div className="desktop-persona-selection-viewer-thread-group-traits-list">
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">Female</h6>
                                </div>
                                <div className="desktop-persona-selection-viewer-thread-group-trait">
                                    <h6 className="desktop-persona-selection-viewer-thread-group-trait-text">Software Engineer</h6>
                                </div>
                            </div>
                        </div>
                        <div className="phone-message-groups">
                            <div className="phone-message-group">
                                <div className="phone-request-message">
                                    <p className="phone-request-message-text">Do you use any coding copilots? Why or why not?</p>
                                </div>
                                <div className="phone-response-messages">
                                    <div className="phone-response-bar" />
                                    <div className="phone-response-message">
                                        <p className="phone-response-message-text">Yes, use a copilot when writing SQL queries and unit tests. It's really helpful for syntax in those contexts, but it's not very useful in writing backend code.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="phone-message-group">
                                <div className="phone-request-message">
                                    <p className="phone-request-message-text">I'm building a new UI for copilots, specifically for coding, where it outputs pseudocode before writing the code, and the pseudocode is persisted in the comments.</p>
                                </div>
                                <div className="phone-response-messages">
                                    <div className="phone-response-bar" />
                                    <div className="phone-response-message">
                                        <p className="phone-response-message-text">Could you integrate this with any code editor? I exclusively use Visual Studio Code.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}