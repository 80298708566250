import React from 'react';

import TraitList from './TraitList';

export default function Industry(props) {
    const industries = ["Medical", "Legal", "Delivery", "Consulting", "Investing", "Finance"];

    return (
        <TraitList
            category={"Industry"}
            list={industries}
            selected={props.orderTraits["Industry"]}
            onAdd={props.onAdd}
            onRemove={props.onRemove} />
    );
}