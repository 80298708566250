import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { NavbarLogo } from '../components';
import { ResponderBase } from '../components/responder';

import { useAuth } from '../hooks';

export default function ResponderAuthenticatedPage(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const [userProfileDropdownState, setUserProfileDropdownState] = useState(false);

    const [earnings, setEarnings] = useState(null);

    useEffect(() => {
        setEarnings(43.12);
    }, []);

    const handleBasePageClick = () => {
        setUserProfileDropdownState(false);
    };

    const toggleUserProfileDropdownState = (e) => {
        e.stopPropagation();
        setUserProfileDropdownState(state => !state);
    };

    const goToAsk = () => {
        navigate('/');
    };

    const signOut = () => {
        auth.signOut();
    };

    return (
        <div className="page-base" onClick={handleBasePageClick}>
            <div className="navbar border">
                <div className="navbar-container">
                    {/* <NavbarLogo /> */}
                    <div className="earnings-pill">
                        <div className="earnings-pill-content">
                            <p className="green-header-5-text">{"$" + earnings}</p>
                        </div>
                    </div>
                    <div className="navbar-right-container">
                        <div className="user-profile-dropdown">
                            <div className="user-button" onClick={(e) => toggleUserProfileDropdownState(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                </svg>
                            </div>
                            {
                                userProfileDropdownState && (
                                    <div className="user-profile-dropdown-content">
                                        <div className="user-profile-dropdown-item" onClick={goToAsk}>
                                            <p className="black-regular-5-text">Talk to customers now</p>
                                        </div>
                                        <hr className="user-profile-dropdown-divider" />
                                        <div className="user-profile-dropdown-item" onClick={signOut}>
                                            <p className="black-header-5-text">Log out</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <main className="requester-base">
                <ResponderBase />
            </main>
        </div>
    );
}