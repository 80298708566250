import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './BlogPosts.css';

import { Navbar, Footer } from '../../components';
import TheRisksThatEveryStartupFaces from './TheRisksThatEveryStartupFaces';

export default function BlogPostBase(props) {
    const { slug } = useParams();

    return (
        <div className="page-base">
            <Navbar type="REQUESTER" />
            <section className="content-background white">
                <div className="content-section">
                    <div className="blog-post-section">
                        <Link to='/blog' className="blog-post-ancestry">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="18" height="18" viewBox="0 0 24 24" strokeWidth="3" stroke="#609C5E" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M15 6l-6 6l6 6" />
                            </svg>
                            <p className="blog-post-ancestry-button-text">All blog posts</p>
                        </Link>
                        {
                            slug === "the-risks-that-every-startup-faces" && <TheRisksThatEveryStartupFaces />
                        }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}