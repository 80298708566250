import React, { useEffect, useState } from 'react';
import { SignUp, SignIn, VerificationCode } from '.';

export default function AuthController(props) {
    const [authState, setAuthState] = useState(props.authState);

    useEffect(() => {
        setAuthState(props.authState);
    }, [props.authState]);

    const exit = (e) => {
        if (e === undefined || e.target === e.currentTarget) {
            setAuthState("UNSET");
            props.deactivate();
        }
    };

    const lateralState = () => {
        if (authState === "SIGN_IN") {
            setAuthState("SIGN_UP");
        } else if (authState === "SIGN_UP") {
            setAuthState("SIGN_IN");
        } else if (authState === "SIGN_IN_THROUGH_ORDER") {
            setAuthState("SIGN_UP_THROUGH_ORDER");
        } else if (authState === "SIGN_UP_THROUGH_ORDER") {
            setAuthState("SIGN_IN_THROUGH_ORDER");
        }
    };

    const nextState = () => {
        if (authState === "SIGN_IN" || authState === "SIGN_UP") {
            setAuthState("VERIFICATION_CODE");
        } else if (authState === "VERIFICATION_CODE") {
            setAuthState("UNSET");
            props.deactivate();
        } else if (authState === "SIGN_IN_THROUGH_ORDER" || authState === "SIGN_UP_THROUGH_ORDER") {
            setAuthState("VERIFICATION_CODE_THROUGH_ORDER");
        } else if (authState === "VERIFICATION_CODE_THROUGH_ORDER") {
            setAuthState("UNSET");
            props.deactivate();
            props.activateOrderCheckout();
        }
    };

    return (
        <>
            {
                authState === "SIGN_UP" && <SignUp title={"Welcome to Userverse"} lateral={lateralState} next={nextState} exit={exit} accountType={props.accountType} email={props.email} />
            }
            {
                authState === "SIGN_UP_THROUGH_ORDER" && <SignUp title={"Create an account to place your order"} lateral={lateralState} next={nextState} exit={exit} accountType={props.accountType} email={props.email} />
            }
            {
                authState === "SIGN_IN" && <SignIn lateral={lateralState} next={nextState} exit={exit} />
            }
            {
                authState === "SIGN_IN_THROUGH_ORDER" && <SignIn lateral={lateralState} next={nextState} exit={exit} />
            }
            {
                authState === "VERIFICATION_CODE" && <VerificationCode next={nextState} exit={exit} />
            }
            {
                authState === "VERIFICATION_CODE_THROUGH_ORDER" && <VerificationCode next={nextState} exit={exit} />
            }
        </>
    );
}