import React from 'react';

import { ResponderActionPreview } from '.';

export default function ResponderActionList(props) {
    return (
        <div className="responder-thread-list">
            {
                props.actions.map((action, i) =>
                    <ResponderActionPreview 
                        key={action["action_uuid"]}
                        action={action}
                        isSelected={props.selectedAction === i}
                        onClick={() => props.onClick(i)} />
                )
            }
        </div>
    );
}