import React from 'react';

import { RequesterThreadPreview } from '.';
import CharacteristicList from '../CharacteristicList';

export default function RequesterThreadList(props) {
    return (
        <div className="requester-thread-list">
            <div className="requester-thread-list-groups">
                {
                    props.threads.map((threadGroup, groupIndex) => 
                        <div key={groupIndex} className="requester-thread-list-group">
                            <CharacteristicList characteristics={threadGroup["requested_traits"]} />
                            <div className="requester-thread-list-group-threads">
                                {
                                    threadGroup["threads"].map((thread, threadIndex) =>
                                        <RequesterThreadPreview 
                                            key={thread["thread_uuid"]}
                                            thread={thread}
                                            isSelected={props.selectedGroupIndex === groupIndex && props.selectedThreadIndex === threadIndex}
                                            onClick={() => props.onClick(groupIndex, threadIndex, thread["thread_uuid"])} />
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}