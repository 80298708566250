import React, { useEffect, useState } from 'react';

import Modal from '../Modal';

import { useAuth, useInput } from '../../hooks';

export default function SignUp(props) {
    const auth = useAuth();

    const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput("");
    const { value: lastName, bind: bindLastName, reset: resetLastName } = useInput("");
    const { value: email, bind: bindEmail, reset: resetEmail } = useInput(props.email === undefined ? "" : props.email);

    const [loading, setLoading] = useState(false);

    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    
    useEffect(() => {
        setFirstNameError(null);
    }, [firstName]);

    useEffect(() => {
        setLastNameError(null);
    }, [lastName]);

    useEffect(() => {
        setEmailError(null);
    }, [email]);

    const handleSubmit = () => {
        var nameRegex = /^[a-z ,.'-]+$/i;
        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var valid = true;
        if (firstName === "") {
            setFirstNameError("First name is required");
            valid = false;
        } else if (!firstName.match(nameRegex)) {
            setFirstNameError("Not a valid first name");
            valid = false;
        }
        if (lastName === "") {
            setLastNameError("Last name is required");
            valid = false;
        } else if (!lastName.match(nameRegex)) {
            setLastNameError("Not a valid last name");
            valid = false;
        }
        if (email === "") {
            setEmailError("Email is required");
            valid = false;
        } else if (!email.match(emailRegex)) {
            setEmailError("Not a valid email");
            valid = false;
        }

        if (valid) {
            setLoading(true);
            auth.handleSignUp(firstName, lastName, email, props.accountType).then(() => {
                auth.handleSignIn(email).then(() => {
                    props.next();
                }).catch((error) => {
                    setLoading(false);
                    console.log(error);
                })
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const exit = (e) => {
        if (e === undefined || e.target === e.currentTarget) {
            setLoading(false);
            resetFields();
            props.exit(e);
        }
    };

    const resetFields = () => {
        resetFirstName();
        resetLastName();
        resetEmail();
    };

    return (
        <Modal 
            detail={
                <div className="auth-modal-base">
                    <div className="form-group">
                        <h4 className="black-header-5-text">Your name</h4>
                        <div className="form-level">
                            <div className="form-text-field">
                                <input className={firstNameError === null ? "text-input-box" : "red-text-input-box"} type="text" placeholder="Your first name" onKeyDown={(e) => handleEnter(e)} {...bindFirstName} />
                                {
                                    firstNameError !== null && (
                                        <div className="auth-modal-error-message">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{firstNameError}</p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="form-text-field">
                                <input className={lastNameError === null ? "text-input-box" : "red-text-input-box"} type="text" placeholder="Your last name" onKeyDown={(e) => handleEnter(e)} {...bindLastName} />
                                {
                                    lastNameError !== null && (
                                        <div className="auth-modal-error-message">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{lastNameError}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <h4 className="black-header-5-text">Your email</h4>
                        <div className="form-level">
                            <div className="form-text-field">
                                <input className={emailError === null ? "text-input-box" : "red-text-input-box"} type="text" placeholder="Your email" onKeyDown={(e) => handleEnter(e)} {...bindEmail} />
                                {
                                    emailError !== null && (
                                        <div className="auth-modal-error-message">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{emailError}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            title={props.title}
            action={"Continue"}
            handleAction={handleSubmit}
            loading={loading}
            secondaryAction={
                <div className="sign-in-secondary-action">
                  <p className="gray-regular-6-text">Already have an account?</p>
                  <p className="green-regular-6-text" onClick={props.lateral}>Sign in instead</p>
                </div>
              }
            exit={exit} />
    );
}