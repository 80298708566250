import React from 'react';
import './Navbar.css';
import { useNavigate, Link } from 'react-router-dom';

import NavbarLogo from './NavbarLogo';

import { useAuth } from '../../hooks';

export default function Navbar(props) {
  const navigate = useNavigate();
  const auth = useAuth();

  const goToEarn = () => {
    navigate('/earn');
  };

  const signOut = () => {
    auth.signOut();
  };
    
  return (
    <header className="navbar border">
      <div className="navbar-container">
        <NavbarLogo />
        {
          props.type === "REQUESTER" && (
            <nav className="nav-links">
              {/* <div className="nav-link-dropdown">
                <div className="nav-link-dropdown-button">Use Cases</div>
                <div className="nav-link-dropdown-content-container">
                  <div className="nav-link-dropdown-content">
                    <Link to='/use-cases/user-research' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-search" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h1.5" />
                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                        <path d="M20.2 20.2l1.8 1.8" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">User Research</h5>
                        <p className="nav-link-dropdown-content-item-detail-text">Learn about a target audience</p>
                      </div>
                    </Link>
                    <Link to='/use-cases/validation' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-browser-check" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z" />
                        <path d="M4 8h16" />
                        <path d="M8 4v4" />
                        <path d="M9.5 14.5l1.5 1.5l3 -3" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Validation</h5>
                        <p className="nav-link-dropdown-content-item-detail-text">Confirm you're onto something big</p>
                      </div>
                    </Link>
                    <Link to='/use-cases/feedback' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ear" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M6 10a7 7 0 1 1 13 3.6a10 10 0 0 1 -2 2a8 8 0 0 0 -2 3a4.5 4.5 0 0 1 -6.8 1.4" />
                        <path d="M10 10a3 3 0 1 1 5 2.2" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Feedback</h5>
                        <p className="nav-link-dropdown-content-item-detail-text">Speed up your iteration cycles</p>
                      </div>
                    </Link>
                    <Link to='/use-cases/beta-testing' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-test-pipe" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M20 8.04l-12.122 12.124a2.857 2.857 0 1 1 -4.041 -4.04l12.122 -12.124" />
                        <path d="M7 13h8" />
                        <path d="M19 15l1.5 1.6a2 2 0 1 1 -3 0l1.5 -1.6z" />
                        <path d="M15 3l6 6" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Beta Testing</h5>
                        <p className="nav-link-dropdown-content-item-detail-text">Catch issues before your customers do</p>
                      </div>
                    </Link>
                    <Link to='/use-cases/opportunity-identification' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-map-search" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M11 18l-2 -1l-6 3v-13l6 -3l6 3l6 -3v7.5" />
                        <path d="M9 4v13" />
                        <path d="M15 7v5" />
                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                        <path d="M20.2 20.2l1.8 1.8" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Opportunity Identification</h5>
                        <p className="nav-link-dropdown-content-item-detail-text">Discover problems to solve</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="nav-link-dropdown">
                <div className="nav-link-dropdown-button">Resources</div>
                <div className="nav-link-dropdown-content-container">
                  <div className="nav-link-dropdown-content">
                    <Link to='/blog' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                        <path d="M13 8l2 0" />
                        <path d="M13 12l2 0" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Blog</h5>
                      </div>
                    </Link>
                    {/* <Link to='/support' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M12 17l0 .01" />
                        <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Support</h5>
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="nav-link-dropdown">
                <div className="nav-link-dropdown-button">Company</div>
                <div className="nav-link-dropdown-content-container">
                  <div className="nav-link-dropdown-content">
                    <Link to='/about' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-target-arrow" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                        <path d="M12 7a5 5 0 1 0 5 5" />
                        <path d="M13 3.055a9 9 0 1 0 7.941 7.945" />
                        <path d="M15 6v3h3l3 -3h-3v-3z" />
                        <path d="M15 9l-3 3" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">About</h5>
                      </div>
                    </Link>
                    <Link to='/jobs' className="nav-link-dropdown-content-item">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-briefcase" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                        <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                        <path d="M12 12l0 .01" />
                        <path d="M3 13a20 20 0 0 0 18 0" />
                      </svg>
                      <div className="nav-link-dropdown-content-item-text-container">
                        <h5 className="nav-link-dropdown-content-item-title-text">Jobs</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          )
        }
        <div className="navbar-actions-container">
          {
            props.type === "REQUESTER" ? <Link to='/earn' className="earn-as-user-button">Earn as a User</Link> : <Link to='/' className="earn-as-user-button">Talk to potential users now</Link>
          }
          <button className="login-button" onClick={props.activateAuthModal}>Log in</button>
        </div>
      </div>
    </header>
  );
}