import React from 'react';

import { formatTimestamp } from '../../utils';

export default function RequesterThreadPreview(props) {
    return (
        <div className={props.isSelected ? "requester-thread-list-group-thread selected" : "requester-thread-list-group-thread"} onClick={props.onClick}>
            <div className={`requester-thread-list-group-thread-status${(props.thread["requester_context"]["requester_state"] === "UNREAD") ? " unread" : " read"}`} />
            <div className="requester-thread-list-group-thread-details">
                <div className="requester-thread-list-group-thread-header">
                    <h4 className="black-header-5-text">{props.thread["requester_context"]["responder_name"]}</h4>
                    <p className="gray-regular-5-text">{formatTimestamp(props.thread["updated_at"])}</p>
                </div>
                {
                    props.thread["requester_context"]["requester_state"] === "NEW" ? (
                        <div className="requester-thread-list-group-thread-new-message-container">
                            <div className="requester-thread-list-group-thread-new-message">
                                <p className="white-header-6-text">NEW</p>
                            </div>
                            <div className="requester-thread-list-group-thread-message">
                                <p className="black-regular-6-text">Send your first message</p>
                            </div>
                        </div>
                    ) : (
                        <div className="requester-thread-list-group-thread-message">
                            <p className="black-regular-6-text">{props.thread["display_text"]}</p>
                        </div>
                    )
                }
            </div>
        </div>
    );
}