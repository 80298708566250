import React, { useEffect, useRef, useState } from 'react';

import { useInput } from '../../hooks';
import CharacteristicList from '../CharacteristicList';

export default function RequesterThreadViewer(props) {
    const threadViewerRef = useRef(null);
    const composerTextRef = useRef(null);

    const { value: composerText, bind: bindComposerText, reset: resetComposerText } = useInput("");

    const [selectedMode, setSelectedMode] = useState(0);

    const actionTypeMap = {
        "USER_RESEARCH": "USER RESEARCH"
    };

    useEffect(() => {
        const threadViewer = threadViewerRef.current;
        if (threadViewer) {
            threadViewer.scrollTop = threadViewer.scrollHeight;
        }
        setSelectedMode(0);
    }, [props.selectedGroup, props.selectedThread]);

    useEffect(() => {
        if (composerTextRef.current) {
            composerTextRef.current.style.height = 0;
            const { scrollHeight } = composerTextRef.current;
            composerTextRef.current.style.height = `${scrollHeight}px`;
        }
    }, [composerText]);

    const handleModeSelection = (mode) => {
        setSelectedMode(mode);
    };

    const handleMessageSend = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        if (selectedMode === null || composerText === null || composerText === "") {
            return;
        }
        props.onSend({
            "thread_uuid": props.thread["thread_uuid"],
            "action_type": "USER_RESEARCH",
            "payload": composerText
        });

        resetComposerText();
    };

    return (
        <div className="requester-thread-viewer">
            <div className="requester-threads-viewer-header">
                <div className="requester-threads-viewer-header-details">
                    <h3 className="black-header-4-text">{props.thread["requester_context"]["responder_name"]}</h3>
                    <CharacteristicList characteristics={props.orderTraits} />
                </div>
            </div>
            <div className="requester-threads-viewer-actions" ref={threadViewerRef}>
                {
                    props.actions !== undefined && props.actions.map((action) =>
                        <div key={action["action_uuid"]} className="requester-threads-viewer-action">
                            <div className="requester-threads-viewer-action-request">
                                <h5 className="green-header-6-text">{actionTypeMap[action["action_type"]]}</h5>
                                <div>
                                    <div className="requester-threads-viewer-message sent">
                                        <p className="white-regular-5-text">{action["action_payload"]}</p>
                                    </div>
                                </div>
                            </div>
                            {
                                action.messages !== undefined && action.messages.length > 0 && (
                                    <div className="requester-threads-viewer-action-response">
                                        <div className="requester-threads-viewer-action-response-bar">
                                        </div>
                                        <div className="requester-threads-viewer-action-response-messages">
                                            {
                                                action.messages.map((message) =>
                                                    <div key={message["message_uuid"]} className="requester-threads-viewer-message received">
                                                        <p className="black-regular-5-text">{message["action_payload"]}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div className="requester-threads-viewer-composer">
                {/* <div className="requester-threads-viewer-composer-modes">
                    {
                        props.availableModes.map((mode, i) =>
                            <div key={i} className={`requester-threads-viewer-composer-mode${selectedMode === i ? " selected" : ""}`} onClick={() => handleModeSelection(i)}>{mode}</div>
                        )
                    }
                </div> */}
                <div className="requester-threads-viewer-composer-input-container">
                    <textarea ref={composerTextRef} className="requester-threads-viewer-composer-input" type="text" placeholder="Type a message" onKeyDown={handleMessageSend} {...bindComposerText} />
                    <div className="requester-threads-viewer-composer-send" onClick={handleSend}>
                        <div className="requester-threads-viewer-composer-send-green-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
