import React from 'react';
import './FeatureList.css';

export default function FeatureList(props) {
    return (
        <div className="feature-list-section">
            <div className="feature-list">
                <div className="feature-list-row">
                    <div className="feature-list-item">
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar" />
                            <h4 className="feature-list-item-title-text">As easy as texting</h4>
                        </div>
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar clear" />
                            <p className="feature-list-item-text">Speaking to potential customers is as easy as texting. No need to schedule a meeting, just send your questions anytime.</p>
                        </div>
                    </div>
                    <div className="feature-list-item">
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar" />
                            <h4 className="feature-list-item-title-text">High-quality responses</h4>
                        </div>
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar clear" />
                            <p className="feature-list-item-text">Our two-way rating system ensures that you get the most helpful responses.</p>
                        </div>
                    </div>
                </div>
                <div className="feature-list-row">
                    <div className="feature-list-item">
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar" />
                            <h4 className="feature-list-item-title-text">Pay as you go</h4>
                        </div>
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar clear" />
                            <p className="feature-list-item-text">Only pay for the responses you receive. The most affordable way to speak to potential customers.</p>
                        </div>
                    </div>
                    <div className="feature-list-item">
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar" />
                            <h4 className="feature-list-item-title-text">The personas you're looking for</h4>
                        </div>
                        <div className="feature-list-item-title">
                            <div className="feature-list-item-title-bar clear" />
                            <p className="feature-list-item-text">With hundreds of traits to filter on, you can connect with audiences that have the right experiences and insights.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}