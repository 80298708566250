import React from 'react';
import './About.css';

import { Navbar, Footer } from '../../components';

export default function About(props) {
    return (
        <div className="page-base">
            <Navbar type="REQUESTER" />
            <section className="content-background white">
                <div className="content-section">
                    <div className="about-section">
                        <div className="about-section-header">
                            <h1 className="about-section-header-text">About Us</h1>
                        </div>

                        <section className="about-section-detail-container">
                            <p className="about-section-detail-text">Our mission is to increase the success rate of startups.</p>

                            <p className="about-section-detail-text">Get in touch at userverse.team@gmail.com.</p>
                        </section>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}