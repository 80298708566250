import React from 'react';

export default function CharacteristicList(props) {
    return (
        <div className="characteristic-list">
            {
                props.characteristics.Occupation !== undefined && (
                    <div className="characteristic-list-item">
                        <p className="black-header-6-text">{props.characteristics.Occupation}</p>
                    </div>
                )
            }
        </div>
    );
}