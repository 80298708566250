import React, { useEffect, useState } from 'react';

import Modal from '../Modal';

import { useAuth, useInput } from '../../hooks';

export default function SignIn(props) {
  const auth = useAuth();

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");

  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    setEmailError(null);
  }, [email]);

  const handleSubmit = () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email === "") {
      setEmailError("Email is required");
      return;
    } else if (!email.match(validRegex)) {
      setEmailError("Not a valid email");
      return;
    }
    setLoading(true);
    auth.handleSignIn(email).then(() => {
      setLoading(false);
      props.next();
    }).catch((error) => {
      setLoading(false);
      setEmailError(error);
    });
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
    }
  };

  const exit = (e) => {
    if (e === undefined || e.target === e.currentTarget) {
      setLoading(false);
      resetEmail();
      props.exit(e);
    }
  };

  return (
    <Modal 
      detail={
        <div className="auth-modal-base">
          <div className="form-group">
            <h2 className="black-header-5-text">Enter your email</h2>
            <div className="form-level">
              <div className="form-text-field">
                <input className={emailError === null ? "text-input-box" : "red-text-input-box"} type="text" placeholder="Your email" onKeyDown={(e) => handleEnter(e)} {...bindEmail} />
                {
                  emailError !== null && (
                    <div className="auth-modal-error-message">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                        <path d="M12 8v4"></path>
                        <path d="M12 16h.01"></path>
                      </svg>
                      <p className="red-regular-5-text">{emailError}</p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      }
      title={"Welcome back to Userverse"}
      action={"Continue"}
      handleAction={handleSubmit}
      loading={loading}
      secondaryAction={
        <div className="sign-in-secondary-action">
          <p className="gray-regular-6-text">New to Userverse?</p>
          <p className="green-regular-6-text" onClick={props.lateral}>Create an account</p>
        </div>
      }
      exit={exit} />
  );
}