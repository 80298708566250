import React, { useEffect, useRef } from 'react';

import { useAuth, useInput } from '../../hooks';

export default function ResponderThreadViewer(props) {
    const auth = useAuth();

    const threadViewerRef = useRef(null);
    const composerTextRef = useRef(null);

    const { value: composerText, bind: bindComposerText, reset: resetComposerText } = useInput("");

    const actionTypeMap = {
        "USER_RESEARCH": "USER RESEARCH"
    };

    useEffect(() => {
        const threadViewer = threadViewerRef.current;
        if (threadViewer) {
            threadViewer.scrollTop = threadViewer.scrollHeight;
        }
    }, [props.selectedIndex]);

    useEffect(() => {
        if (composerTextRef.current) {
            composerTextRef.current.style.height = 0;
            const { scrollHeight } = composerTextRef.current;
            composerTextRef.current.style.height = `${scrollHeight}px`;
        }
    }, [composerText]);

    const handleMessageSend = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSend();
        }
    };

    const handleSend = () => {
        if (composerText === null || composerText === "") {
            return;
        }
        props.onSend({
            "thread_uuid": props.thread.thread["thread_uuid"],
            "action_uuid": props.action["action_uuid"],
            "responder_uuid": auth.user['user_uuid'],
            "payload": composerText
        });
        resetComposerText();
    };

    return (
        <div className="requester-thread-viewer">   
            <div className="requester-threads-viewer-header">
                <div className="requester-threads-viewer-header-details">
                    <h3 className="black-header-4-text">Your thread with {(props.thread !== undefined && props.thread.thread !== undefined && props.thread.thread.responder !== undefined) ? props.thread.thread.responder["requester_name"] : ""}</h3>
                </div>
            </div>         
            <div className="responder-threads-viewer-messages" ref={threadViewerRef}>
                {
                    props.thread !== undefined && props.thread.actions !== undefined && props.thread.actions.map((action) =>
                        <div key={action["action_uuid"]} className="requester-threads-viewer-action">
                            <div className="requester-threads-viewer-action-request">
                                <h5 className="green-header-6-text">{actionTypeMap[action["action_type"]]}</h5>
                                <div>
                                    <div className="requester-threads-viewer-message received">
                                        <p className="black-regular-5-text">{action["payload"]}</p>
                                    </div>
                                </div>
                            </div>
                            {
                                action.messages !== undefined && action.messages.length > 0 && (
                                    <div className="requester-threads-viewer-action-response">
                                        <div className="requester-threads-viewer-action-response-bar">
                                        </div>
                                        <div className="requester-threads-viewer-action-response-messages">
                                            {
                                                action.messages.map((message) =>
                                                    <div key={message["message_uuid"]} className="requester-threads-viewer-message sent">
                                                        <p className="white-regular-5-text">{message["payload"]}</p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <div className="requester-threads-viewer-composer">
                <div className="requester-threads-viewer-composer-input-container">
                    <textarea ref={composerTextRef} className="requester-threads-viewer-composer-input" type="text" placeholder="Type a message" onKeyDown={handleMessageSend} {...bindComposerText} />
                    <div className="requester-threads-viewer-composer-send" onClick={handleSend}>
                        <div className="requester-threads-viewer-composer-send-green-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 12l14 0"></path>
                                <path d="M13 18l6 -6"></path>
                                <path d="M13 6l6 6"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
