import React from 'react';
import './Blog.css';

import { Navbar, Footer } from '../../components';
import { BlogPostPreview } from '../../components/requester';

export default function Blog(props) {
    return (
        <div className="page-base">
            <Navbar type="REQUESTER" />
            <section className="content-background white">
                <div className="content-section">
                    <div className="blog-section">
                        <BlogPostPreview category="Startup Journey" date="June 10, 2024" title="The Risks that Every Startup Faces" authorName="Prithvi Ranjan" authorTitle="Co-founder and CEO" preview="There are millions of startups, yet only a few survive. Does your startup have what it takes to survive?" link="/blog/the-risks-that-every-startup-faces" />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}