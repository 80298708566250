import React from 'react';

import { formatType } from '../../utils';

export default function ResponderActionPreview(props) {
    return (
        <div className={`responder-thread-list-thread${props.isSelected ? " selected" : ""}${props.action["status"] === "UNREAD" ? " unread" : " read"}`} onClick={props.onClick}>
            <div className="responder-thread-list-thread-details">
                {
                    props.action["expires_at"] === undefined ? (
                        <div className="responder-thread-list-thread-type">
                            <p className="white-header-5-text">{formatType(props.action["action_type"])}</p>
                        </div>
                    ) : (
                        <div className="responder-thread-list-thread-type" style={{background: "linear-gradient(to right, #609C5E " + (props.action["expires_at"] - (Date.now() / 1000)) / (props.action["expires_at"] - props.action["created_at"]) * 100 + "%, #40673F " + (props.action["expires_at"] - (Date.now() / 1000)) / (props.action["expires_at"] - props.action["created_at"]) * 100 + "%, #40673F 100%"}}>
                            <p className="white-header-5-text">{formatType(props.action["action_type"])}</p>
                        </div>
                    )
                }
                <div className="responder-thread-list-thread-fare-and-estimate">
                    <p className="black-header-3-text">{"$" + props.action["fare"]}</p>
                    <div className="responder-thread-list-thread-estimate">
                        <p className="black-regular-6-text">{"Est. " + props.action["estimated_time"] + " min"}</p>
                    </div>
                </div>
            </div>
            <div className="responder-thread-list-thread-message">
                <p className="black-regular-5-text">{props.action["payload"]}</p>
            </div>
        </div>
    );
}