import React from 'react';
import './PrivacyPolicy.css';

import { Navbar, Footer }  from '../../components';

export default function PrivacyPolicy(props) {
    return (
        <div className="page-base">
            <Navbar type="REQUESTER" />
            <main>
                <div className="content-section">
                    <div className="privacy-policy">
                        <div className="privacy-policy-header">
                            <h1 className="black-header-1-text">Privacy Policy</h1>
                            <p className="black-regular-5-text">Effective Date: April 15, 2024</p>
                        </div>

                        <div className="privacy-policy-table-of-contents">
                            <h2 className="black-header-3-text">Table of Contents</h2>
                            <p className="black-regular-5-text"><a href="#introduction">1. Introduction</a></p>
                            <p className="black-regular-5-text"><a href="#information-we-collect">2. Information We Collect</a></p>
                            <p className="black-regular-5-text"><a href="#how-we-use-your-information">3. How We Use Your Information</a></p>
                            <p className="black-regular-5-text"><a href="#disclosure-of-your-information">4. Disclosure of Your Information</a></p>
                            <p className="black-regular-5-text"><a href="#third-party-websites">5. Third-Party Websites</a></p>
                            <p className="black-regular-5-text"><a href="#security-of-your-information">6. Security of Your Information</a></p>
                            <p className="black-regular-5-text"><a href="#changes-to-this-privacy-policy">7. Changes to This Privacy Policy</a></p>
                            <p className="black-regular-5-text"><a href="#contact-us">8. Contact Us</a></p>
                        </div>

                        <section id="introduction">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">1. Introduction</h3>
                                <p className="black-regular-5-text">Welcome to Userverse Inc. We are committed to protecting the privacy and security of your personal information. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our marketplace, including any other media form, media channel, mobile website, or mobile application related thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.</p>
                            </div>
                        </section>

                        <section id="information-we-collect">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">2. Information We Collect</h3>
                                <p className="black-regular-5-text">We collect information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a specific user or device (“personal information”). In particular, our Site has collected the following categories of personal information from its users within the last twelve (12) months:</p>
                                <ol type="a" className="privacy-policy-list">
                                    <li><p className="black-regular-5-text"><strong>Personal Identifiers:</strong> We collect email addresses, and payment information from Requesters; pseudonymous identifiers and demographic information such as gender, occupation, industry, interests, and education levels from Responders.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Commercial Information:</strong> Records of services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Financial Data:</strong> Bank account details necessary to process payments to Responders for services rendered.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Internet or Network Activity:</strong> Browsing history, interaction with our website, and responses to marketing materials.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Geolocation Data:</strong> As provided by you for fulfilling the purpose of matching Requesters with Responders.</p></li>
                                </ol>
                            </div>
                        </section>

                        <section id="how-we-use-your-information">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">3. How We Use Your Information</h3>
                                <p className="black-regular-5-text">We use personal information collected via our Site for a variety of business purposes, including:</p>
                                <ul className="privacy-policy-list">
                                    <li><p className="black-regular-5-text">To facilitate the creation of and secure your account on our network.</p></li>
                                    <li><p className="black-regular-5-text">To match Requesters with appropriate Responders based on the persona information provided.</p></li>
                                    <li><p className="black-regular-5-text">To process payments and transactions via Stripe, and to transfer earnings to Responders' bank accounts.</p></li>
                                    <li><p className="black-regular-5-text">To send administrative information to you, such as changes to our terms, conditions, and policies.</p></li>
                                    <li><p className="black-regular-5-text">To protect our Site, prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</p></li>
                                </ul>
                            </div>

                        </section>

                        <section id="disclosure-of-your-information">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">4. Disclosure of Your Information</h3>
                                <p className="black-regular-5-text">We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                                <ol type="a" className="privacy-policy-list">
                                    <li><p className="black-regular-5-text"><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p></li>
                                    <li><p className="black-regular-5-text"><strong>Third Parties Service Providers:</strong> We employ third party companies such as AWS for hosting our data and Stripe for processing payments.</p></li>
                                </ol>
                            </div>
                        </section>

                        <section id="third-party-websites">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">5. Third-Party Websites</h3>
                                <p className="black-regular-5-text">The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this privacy policy, and we cannot guarantee the safety and privacy of your information.</p>
                            </div>
                        </section>

                        <section id="security-of-your-information">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">6. Security of Your Information</h3>
                                <p className="black-regular-5-text">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                            </div>
                        </section>

                        <section id="changes-to-this-privacy-policy">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">7. Changes to This Privacy Policy</h3>
                                <p className="black-regular-5-text">We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible.</p>
                            </div>
                        </section>

                        <section id="contact-us">
                            <div className="privacy-policy-section">
                                <h3 className="black-header-3-text">8. Contact Us</h3>
                                <p className="black-regular-5-text">If you have questions or comments about this policy, you may email us at userverse.team@gmail.com</p>
                            </div>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}